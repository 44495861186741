
import { settings } from "@/libraries/storage";
import { CronJob } from "cron";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const AppStore = namespace('AppStore')
const CoreStore = namespace('CoreStore')

@Component
export default class OverlayUpdate extends Vue {
    hasUpdate = false
    rebooting = false

    size = 206
    width = 20
    value = 0

    @AppStore.Mutation
    setThemeColor!: (themeColor: string | null) => void

    @CoreStore.State
    serviceWorkerRegistration!: ServiceWorkerRegistration | false

    created(): void {
        document.addEventListener('sw.updated', () => this.hasUpdate = true, {once: true})

        if (navigator && ('serviceWorker' in navigator) &&
            ('addEventListener' in navigator.serviceWorker)
        ) {
            navigator.serviceWorker.addEventListener('controllerchange', async () => {
                if (this.rebooting) {
                    return
                }

                this.rebooting = true

                window.location.reload(true)
            })
        }

        new CronJob('*/15 * * * *', () => {
            this.serviceWorkerRegistration && this.serviceWorkerRegistration?.update()
        }, null, true)
    }

    install(): void {
        settings.del('system', 'pwa', 'version')
        settings.set('system', 'serviceWorker', 'lastUpdate', Date.now())

        setTimeout(() => this.hasUpdate = false, 3_000)

        if (!this.serviceWorkerRegistration || !this.serviceWorkerRegistration.waiting) {
            return
        }

        this.serviceWorkerRegistration.waiting.postMessage('sw.skipWaiting')
    }

    get appName(): string {
        return settings.get<string>('ux', 'theme', 'longName')
    }

    @Watch('hasUpdate')
    hasUpdate_(hasUpdate: boolean): void {
        if (hasUpdate) {
            setTimeout(() => {
                this.value = 1

                setTimeout(() => {
                    this.value = 2

                    setTimeout(() => this.install(), Math.floor(Math.random() * (1500 - 800 + 1)) + 800)
                }, Math.floor(Math.random() * (1500 - 800 + 1)) + 800)
            }, Math.floor(Math.random() * (1500 - 800 + 1)) + 800)
        }

        this.setThemeColor(hasUpdate ? "#ffffff" : null)
    }
}
