
import OverlayUpdate from "@/components/OverlayUpdate.vue";
import { assign, includes } from "lodash";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";
import { version } from '../package.json';
import { Settings } from "luxon";
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from "vuex-class";
import { v4 as uuidv4 } from "uuid";
import { settings } from "@/libraries/storage";

const AppStore = namespace('AppStore')
const CoreStore = namespace('CoreStore')
const VrStore = namespace('VrStore')

@Component({
    components: {
        OverlayUpdate,
    }
})
export default class App extends Vue {
    @AppStore.State
    appLanguage!: string | null
    @AppStore.State
    disableScroll!: boolean
    @AppStore.State
    themeColor!: string

    @AppStore.Mutation
    setAppLanguage!: (language: string) => void
    @AppStore.Mutation
    setFullscreen!: (status: boolean) => void
    @AppStore.Mutation
    setTransition!: (transition?: string) => void

    @CoreStore.State
    serviceWorkerRegistration!: ServiceWorkerRegistration | false

    @CoreStore.Mutation
    setAppOnline!: (status: boolean) => void
    @CoreStore.Mutation
    setServiceWorkerRegistration!: (swRegistration: ServiceWorkerRegistration) => void

    @VrStore.State
    darkMode!: boolean
    @VrStore.State
    darkTheme!: Partial<VuetifyThemeVariant> | null
    @VrStore.State
    lightTheme!: Partial<VuetifyThemeVariant> | null
    @VrStore.State
    longName!: string
    @VrStore.State
    shortName!: string

    created(): void {
        window.addEventListener('online', () => this.setAppOnline(true))
        window.addEventListener('offline', () => this.setAppOnline(false))

        if (navigator && ('serviceWorker' in navigator) &&
            ('addEventListener' in navigator.serviceWorker)
        ) {
            navigator.serviceWorker.getRegistrations()
                .then((sws) => {
                    if (sws.length > 0) {
                        for (const sw of sws) {
                            const {active} = sw

                            if (active) {
                                if (active.scriptURL.endsWith('/service-worker.js')) {
                                    this.setServiceWorkerRegistration(sw)
                                }
                            }
                        }
                    }
                })
        }

        if (!settings.has('system', 'app', 'uuid')) {
            settings.set('system', 'app', 'uuid', uuidv4())
        }

        if (!settings.has('system', 'serviceWorker', 'lastUpdate')) {
            settings.set('system', 'serviceWorker', 'lastUpdate', Date.now())
        }

        if (!settings.has('system', 'pwa', 'version') ||
            (this.serviceWorkerRegistration === false)
        ) {
            settings.set('system', 'pwa', 'version', version)
        }

        this.setAppLanguage(this.navigatorLanguage)
        this.breakpoint(this.$vuetify.breakpoint.width)

        // eslint-disable-next-line no-self-assign
        this.title = this.title

        if (this.darkTheme) {
            this.$vuetify.theme.themes.dark = assign(this.$vuetify.theme.themes.dark, this.darkTheme)
        }
        if (this.lightTheme) {
            this.$vuetify.theme.themes.light = assign(this.$vuetify.theme.themes.light, this.lightTheme)
        }
        this.$vuetify.theme.dark = this.darkMode
    }

    get navigatorLanguage(): string {
        if (settings.has('system', 'pwa', 'language')) {
            return settings.get<string>('system', 'pwa', 'language')
        }

        let navigatorLanguage = navigator.language.replace(/-[a-z]{2,3}$/i, '')
        if (!includes(this.$i18n.availableLocales, navigatorLanguage)) {
            return navigatorLanguage
        }

        for (const lang of navigator.languages) {
            navigatorLanguage = lang.replace(/-[a-z]{2,3}$/i, '')
            if (!includes(this.$i18n.availableLocales, navigatorLanguage)) {
                return navigatorLanguage
            }
        }

        return process.env.VUE_APP_I18N_FALLBACK_LOCALE as string
    }

    get title(): string | null {
        return settings.get<string>('ux', 'theme', 'longName')
    }
    set title(title: string | null) {
        const titleElement = document.querySelector('title')
        if (titleElement) {
            titleElement.innerText = title || "..."
        }
    }

    breakpoint(width: number): void {
        const fullscreen = (width < 600)

        this.setFullscreen(fullscreen);
        this.setTransition(fullscreen ? 'dialog-bottom-transition' : 'dialog-transition')
    }

    @Watch('$vuetify.breakpoint.width')
    vuetifyBreakpointWidth_(width: number): void {
        this.breakpoint(width)
    }

    @Watch('appLanguage')
    appLanguage_(language: string): void {
        Settings.defaultLocale = language
        this.$i18n.locale = language
        this.$vuetify.lang.current = language

        const html = document.querySelector('html')
        if (html) {
            html.setAttribute('lang', language)
        }

        settings.set('system', 'pwa', 'language', language)
    }

    @Watch('longName')
    longName_(longName: string): void {
        this.title = longName
    }

    @Watch('darkMode')
    darkMode_(darkMode: boolean): void {
        this.$vuetify.theme.dark = darkMode
    }
    @Watch('darkTheme')
    darkTheme_(darkTheme: VuetifyThemeVariant | null): void {
        if (darkTheme) {
            this.$vuetify.theme.themes.dark = assign(this.$vuetify.theme.themes.dark, darkTheme)
        }
    }
    @Watch('lightTheme')
    lightTheme_(lightTheme: VuetifyThemeVariant | null): void {
        if (lightTheme) {
            this.$vuetify.theme.themes.light = assign(this.$vuetify.theme.themes.light, lightTheme)
        }
    }

    @Watch('disableScroll')
    disableScroll_(status: boolean): void {
        const html = document.querySelector('html')
        const body = document.querySelector('body')

        if (html && body) {
            if (status) {
                html.classList.add('no-scroll')
                body.classList.add('no-scroll')
            } else {
                html.classList.remove('no-scroll')
                body.classList.remove('no-scroll')
            }
        }
    }

    @Watch('themeColor')
    themeColor_(themeColor: string): void {
        const meta: HTMLMetaElement | null = document.querySelector('meta[name=theme-color]')
        if (meta) {
            meta.content = themeColor
        }
    }
}
